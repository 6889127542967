import { useRef, useState } from 'react';
import { DropZone } from '../DropZone';
import { SellerButton } from '../SellerButton';
import { ListVehiclePoolRowFromServer, ListVehiclePoolStatus } from './types';
import { useRemoveVehicleRequestPoolIdMutation } from '../../../../services/api/list-vehicles';
import { useOptimisticRequestRemoval } from '../../hooks/useOptimisticUpdates';
import styled from 'styled-components';
import {
  clearSelectedItemInPool,
  setSelectedItemInPool,
  useGetSelectedRequest
} from '../../../../services/listVehiclesSlice/listVehiclesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEventListener } from 'usehooks-ts';
import ConfirmModal from '../../../Modals/ConfirmModal';
import { modalsToggle } from '../../../../actions';

const SellerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const EmptyRow = styled.div`
  height: 32px;
  width: 100%;
`;

export const SellerRequests = ({ item }: { item: ListVehiclePoolRowFromServer }) => {
  const [removeRequestFromPool] = useRemoveVehicleRequestPoolIdMutation();
  const optimisticUpdate = useOptimisticRequestRemoval();
  const sellerWrapperRef = useRef<any>(null);

  const dispatch = useDispatch();

  const activeRequest = useGetSelectedRequest();
  const activeRequestId = activeRequest?.id;

  const modals = useSelector((state: any) => state.modals);

  useEventListener(
    'mousedown',
    event => {
      const target = event.target as Node;

      if (!target || !target.isConnected) {
        return;
      }

      const popoverElement = document.querySelector('#chat-popover');
      if (popoverElement && popoverElement.contains(target)) return;

      const deleteElement = document.querySelector('#delete-action');
      if (deleteElement && deleteElement.contains(target)) return;

      const { open, modalId } = modals || {};
      if (open && modalId?.startsWith('delete-store')) return;

      const isOutside = sellerWrapperRef.current && !sellerWrapperRef.current.contains(target);

      if (isOutside) {
        dispatch(clearSelectedItemInPool());
      }
    },
    undefined,
    {}
  );

  // useOnClickOutside(sellerWrapperRef, () => dispatch(clearSelectedItemInPool()));

  const disableDropZone = [ListVehiclePoolStatus.Done, ListVehiclePoolStatus.Submitted].includes(
    item.status as ListVehiclePoolStatus
  );

  return (
    <DropZone id={`idleRow-${item.id}`} disabled={disableDropZone}>
      <SellerWrapper ref={sellerWrapperRef}>
        {item.listRequests.map(r => {
          const isActive = activeRequestId === r.id;
          const deleteAction = () => {
            optimisticUpdate(item.id, r);
            removeRequestFromPool(r.id);
            dispatch(clearSelectedItemInPool());
          };
          const setActive = () => dispatch(setSelectedItemInPool(r));

          const openDeleteModal = () => {
            console.log('open delete modal');
            dispatch(modalsToggle(`delete-store-${r.id}`));
          };

          const confirmOnDelete = item.lister_id && item.status === 'submitted';

          return (
            <>
              <SellerButton
                status={r.status}
                seller={{ ...r.user, vehiclesRange: r.number_of_vehicles }}
                key={r.id}
                requestId={r.id}
                clickAction={setActive}
                isActive={isActive}
                canEditNumberOfVehicles
                deleteAction={(() => {
                  if (!isActive) return null;
                  return confirmOnDelete ? openDeleteModal : deleteAction;
                })()}
              />
              {confirmOnDelete && (
                <ConfirmModal
                  title="Store will be deleted and will affect Lister’s road"
                  handleSubmit={deleteAction}
                  submitText={'Confirm'}
                  modalId={`delete-store-${r.id}`}
                />
              )}
            </>
          );
        })}
        {item.listRequests.length === 0 && <EmptyRow />}
      </SellerWrapper>
    </DropZone>
  );
};
