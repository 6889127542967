import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import { snackShow, listBids, filterBids } from '../actions';
import {
  deleteProxyBid,
  DELETE_PROXY_BID_REQUEST,
  editProxyBid,
  EDIT_PROXY_BID_REQUEST,
  editBid,
  EDIT_BID_REQUEST,
  deleteBid,
  DELETE_BID_REQUEST,
} from '../actions/bids';
import { LIST_BIDS_REQUEST, FILTER_BIDS_REQUEST } from '../constants/actionTypes';
import { Api } from '../utils';

const getAuctionStatus = state => state.bids.auctionStatus;

function* getCurrentFiler(auctionState) {
  if (auctionState === 'active') {
    return yield select(state => getFormValues('bidsActiveFilterForm')(state));
  }
  if (auctionState === 'pending') {
    return yield select(state => getFormValues('bidsPendingFilterForm')(state));
  }
  if (auctionState === 'ended') {
    return yield select(state => getFormValues('bidsEndingFilterForm')(state));
  }
  return {};
}

function* fetchBids({ payload = {} }) {
  try {
    // const { user } = yield select(state => state.user);
    // console.log(user, 'fetchBids')
    // if (!user || user.role !== 'buyer') return;
    const auctionStatus = yield select(getAuctionStatus);
    const filter = yield getCurrentFiler(auctionStatus);
    const { data } = yield call(Api.bids.fetchBids, {
      ...payload,
      params: { auctionStatus, ...filter, ...payload.params }
    });
    yield put(listBids(data).success);
  } catch (e) {
    yield put(listBids(e).failure);
  }
}

function* filterBidsSaga({ payload = {} }) {
  try {
    const auctionStatus = yield select(getAuctionStatus);
    const filter = yield getCurrentFiler(auctionStatus);
    const { data } = yield call(Api.bids.fetchBids, {
      ...payload,
      params: { auctionStatus, ...filter, ...payload.params }
    });
    yield put(filterBids(data).success);
  } catch (e) {
    yield put(filterBids(e).failure);
  }
}

function* editBidSaga({ payload }) {
  try {
    const { data } = yield call(Api.bids.editBid, payload.id, payload);
    yield put(editBid(data).success);
    yield put(snackShow({ message: data.message }));
  } catch (e) {
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
    yield put(editBid(e).failure);
  }
}

function* editProxyBidSaga({ payload }) {
  try {
    const { data } = yield call(Api.bids.editProxyBid, payload.id, payload);
    yield put(editProxyBid(data).success);
    yield put(snackShow({ message: data.message }));
  } catch (e) {
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
    yield put(editProxyBid(e).failure);
  }
}

function* deleteBidSaga({ payload }) {
  try {
    const { data } = yield call(Api.bids.deleteBid, payload);
    yield put(deleteBid(data).success);
    yield put(snackShow({ message: data.message }));
  } catch (e) {
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
    yield put(deleteBid(e).failure);
  }
}

function* deleteProxyBidSaga({ payload }) {
  try {
    const { data } = yield call(Api.bids.deleteProxyBid, payload);
    yield put(deleteProxyBid(data).success);
    yield put(snackShow({ message: data.message }));
  } catch (e) {
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
    yield put(deleteProxyBid(e).failure);
  }
}

export default function*() {
  yield all([
    takeLatest(LIST_BIDS_REQUEST, fetchBids),
    takeLatest(EDIT_BID_REQUEST, editBidSaga),
    takeLatest(EDIT_PROXY_BID_REQUEST, editProxyBidSaga),
    takeLatest(DELETE_BID_REQUEST, deleteBidSaga),
    takeLatest(DELETE_PROXY_BID_REQUEST, deleteProxyBidSaga),
    takeLatest(FILTER_BIDS_REQUEST, filterBidsSaga),
  ]);
}
