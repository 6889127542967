import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ListVehiclesRequest } from '../../components/ListVehicles/components/ListVehiclesRow/types';

export type ActiveItem = ListVehiclesRequest & {
  dayNumber?: number;
  orderIndex?: number;
};

type DraggableState = {
  activeItem: ActiveItem | null;
  activeItemType: 'seller-block' | null;
};

type MapFilters = {
  date?: string;
  listerId?: number;
  currentWeekOnly?: boolean;
};

export type ListVehiclesState = {
  isVehiclePoolModalOpen: boolean;
  rightPanelExpanded: boolean;
  selectedWeekType: string;
  draggableState: DraggableState;
  selectedItemInPool: ListVehiclesRequest | null;
  poolFilters: Record<string, any>;
  isMapOpen: boolean;
  showPolylines: boolean;
  mapFilters: MapFilters;
};

const initialState: ListVehiclesState = {
  isVehiclePoolModalOpen: false,
  isMapOpen: false,
  rightPanelExpanded: false,
  selectedWeekType: 'current',
  draggableState: {
    activeItem: null,
    activeItemType: null
  },
  selectedItemInPool: null,
  poolFilters: {},
  mapFilters: {},
  showPolylines: false
};

const listVehiclesSlice = createSlice({
  name: 'listVehicles',
  initialState,
  reducers: {
    openVehiclePoolModal: state => {
      state.isVehiclePoolModalOpen = true;
    },
    closeVehiclePoolModal: state => {
      state.isVehiclePoolModalOpen = false;
    },
    setDraggableItem: (state, action: PayloadAction<ActiveItem>) => {
      state.draggableState.activeItem = action.payload;
      state.draggableState.activeItemType = 'seller-block';
    },
    clearDraggableItem: state => {
      state.draggableState = {
        activeItem: null,
        activeItemType: null
      };
    },
    toggleShowPolylines: state => {
      state.showPolylines = !state.showPolylines;
    },
    toggleRightPanel: state => {
      state.rightPanelExpanded = !state.rightPanelExpanded;
    },
    toggleMapOpen: state => {
      state.isMapOpen = !state.isMapOpen;
    },
    setWeekType: (state, action: PayloadAction<string>) => {
      state.selectedWeekType = action.payload;
    },
    setSelectedItemInPool: (state, action: PayloadAction<ListVehiclesRequest>) => {
      state.selectedItemInPool = action.payload;
    },
    clearSelectedItemInPool: state => {
      state.selectedItemInPool = null;
    },
    setPoolFilterValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const { key, value } = action.payload;
      if (!value) {
        delete state.poolFilters[key];
      } else {
        state.poolFilters[key] = value;
      }
    },
    setPoolSortFilters: (state, action: PayloadAction<{ orderColumn: string; orderDirection: 'asc' | 'desc' }>) => {
      state.poolFilters = { ...state.poolFilters, ...action.payload };
    },
    setMapFilters: (state, action: PayloadAction<MapFilters>) => {
      state.mapFilters = { ...action.payload };
    }
  }
});

export const useIsPoolModalOpen = (): boolean => {
  const isOpen = useSelector((state: any) => state.listVehicles.isVehiclePoolModalOpen);
  return isOpen;
};

export const useIsRightPanelExpanded = (): boolean => {
  const isExpanded = useSelector((state: any) => state.listVehicles.rightPanelExpanded);
  return isExpanded;
};

export const useIsMapOpen = (): boolean => {
  const isMapOpen = useSelector<{ listVehicles: ListVehiclesState }, boolean>(state => state.listVehicles.isMapOpen);
  return isMapOpen;
};

export const useSelectedWeekType = (): string => {
  const selectedWeekType = useSelector<{ listVehicles: ListVehiclesState }, string>(
    state => state.listVehicles.selectedWeekType
  );
  return selectedWeekType;
};

export const useGetDraggableItem = () => {
  const item = useSelector<{ listVehicles: ListVehiclesState }, DraggableState>(
    state => state.listVehicles.draggableState
  );

  return item.activeItem;
};

export const useGetSelectedRequest = (): ListVehiclesRequest | null => {
  const id = useSelector((state: any) => state.listVehicles.selectedItemInPool);
  return id;
};

export const useGetMapFilters = () => {
  const filters = useSelector<{ listVehicles: ListVehiclesState }, MapFilters>(state => state.listVehicles.mapFilters);
  return filters;
};

export const useShowPolyline = () => {
  const show = useSelector<{ listVehicles: ListVehiclesState }, boolean>(state => state.listVehicles.showPolylines);
  return show;
};

export const {
  openVehiclePoolModal,
  closeVehiclePoolModal,
  toggleRightPanel,
  setDraggableItem,
  clearDraggableItem,
  setSelectedItemInPool,
  clearSelectedItemInPool,
  setPoolFilterValue,
  setPoolSortFilters,
  setWeekType,
  toggleMapOpen,
  setMapFilters,
  toggleShowPolylines
} = listVehiclesSlice.actions;
export default listVehiclesSlice;
