import styled from 'styled-components';

type Props = {
  status: string;
};

const RoundedLabel = styled.div<{ color: string; background: string }>`
  color: ${props => props.color};
  background: ${props => props.background};
  border-radius: 8px;
  padding: 4px 8px;
  max-width: fit-content;
`;

export const getStatusColor = (status: string) => {
  if (['draft', 'new_listing'].includes(status)) {
    return {
      color: '#757575',
      background: '#F5F5F5'
    };
  }

  if (status === 'done') {
    return {
      color: '#479747',
      background: '#47974714'
    };
  }

  if (status === 'submitted') {
    return {
      background: '#007FF414',
      color: '#3994DE'
    };
  }
  return {
    color: '#757575',
    background: '#F5F5F5'
  };
};

const StatusLabelMap: Record<string, string> = {
  draft: 'Draft',
  new_listing: 'New Listing',
  done: 'Listed',
  submitted: 'Submitted'
};

export const StatusComponent = ({ status }: Props) => {
  const { color, background } = getStatusColor(status);
  const label = StatusLabelMap[status];

  return (
    <RoundedLabel color={color} background={background}>
      <span>{label}</span>
    </RoundedLabel>
  );
};
