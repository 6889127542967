import styled from 'styled-components';
import { NotesComponent } from './NotesComponent';
import { StatusComponent } from './StatusComponent';
import { Actions } from './Actions';
import { SellerButton } from '../SellerButton';
import { getTableRow } from './styles';
import { Form, Field } from 'react-final-form';
import {
  useUpdateVehiclesPoolRowMutation,
  useGetListersQuery,
  useDeletePoolMutation
} from '../../../../services/api/list-vehicles';
import DatePicker from './CheckboxDatePicker';
import { ListVehiclePoolRowFromServer, ListVehiclePoolStatus } from './types';
import Select from 'react-select';
import moment from 'moment';
import { CreateSellerButton } from './CreateSellerButton';
import { DropZone } from '../DropZone';
import { NumberOfVehicles } from './NumberOfVehicles';
import { useDispatch } from 'react-redux';
import { modalsToggle } from '../../../../actions';
import { ConfirmDeleteStoreModal } from '../ConfirmDeleteModal';

const SellerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const VehiclePoolSelect = styled(Select)`
  & > div {
    background: #f5f5f5;
  }
  & .vehicle-pool-select__control {
    border: none;
    border-radius: 0;
  }

  & .vehicle-pool-select__control:hover {
    border: none;
    border-radius: 0;
  }

  & .vehicle-pool-select__control--is-focused {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  & .vehicle-pool-select__dropdown-indicator {
    padding: 6px;
  }

  & .vehicle-pool-select__control {
    min-height: unset;
  }

  & .vehicle-pool-select__menu-list {
    padding: 4px 4px 4px 0;
  }

  & .vehicle-pool-select__option--is-focused {
    background-color: rgba(0, 0, 0, 0.14) !important;
  }

  & .vehicle-pool-select__indicator-separator {
    display: none;
  }

  & .vehicle-pool-select__dropdown-indicator,
  .vehicle-pool-select__dropdown-indicator:hover {
    color: hsl(0, 0%, 40%);
  }

  & .vehicle-pool-select__dropdown-indicator {
    color: hsl(0, 0%, 40%);
  }

  & .vehicle-pool-select__option--is-selected {
    font-weight: 500;
    background-color: #fff !important;
    color: inherit !important;
  }
`;

const TableRow = getTableRow('form');

const formatDate = (date: any) => {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD');
};

export const VehicleRowForm = ({ item }: { item: ListVehiclePoolRowFromServer }) => {
  const [updateVehiclePoolRow, { isLoading }] = useUpdateVehiclesPoolRowMutation();
  const [deleteVehiclePoolRow] = useDeletePoolMutation();

  const dispatch = useDispatch();

  const onSubmit = (values: any) => {
    if (!values || isLoading) return;
    updateVehiclePoolRow({
      id: item.id,
      lister_id: values.lister_id?.value,
      list_requests: values.list_requests,
      // seller_ids: values.sellers?.map((el: any) => Number(el.value)) ?? [],
      seller_preferable_date: formatDate(values.seller_preferable_date),
      preferable_date: formatDate(values.preferable_date),
      notes: values.notes ?? ''
    });
  };

  const { data: listers } = useGetListersQuery();

  const listerOptions =
    listers?.map(el => ({
      value: el.id,
      label: el.first_name
    })) ?? [];

  const initialValues = {
    lister_id: { value: item.lister_id, label: item.lister?.first_name },
    list_requests: item.listRequests?.map(lr => ({
      user_id: lr.user?.id,
      dealership_name: lr.user?.dealership_name,
      number_of_vehicles: lr.number_of_vehicles,
      status: lr.status
    })),
    seller_preferable_date: item.seller_preferable_date ?? moment(),
    preferable_date: item.preferable_date ?? moment()
  };

  const hasDeleteAction = item.status === 'draft' || item.status === 'new_listing' || item.status === 'submitted';

  const handleDeleteButtonClicked = () => {
    if (!hasDeleteAction) return;
    if (item.status === 'draft') {
      return deleteVehiclePoolRow(item.id);
    }
    dispatch(modalsToggle(`delete-pool-${item.id}`));
  };

  const canAddNewVehicles = [ListVehiclePoolStatus.Done, ListVehiclePoolStatus.Submitted].includes(
    item.status as ListVehiclePoolStatus
  );

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} keepDirtyOnReinitialize>
      {({ handleSubmit, form, values }) => {
        return (
          <TableRow id={`rowForm-${item.id}`} onSubmit={handleSubmit}>
            <Field name="lister_id">
              {props => (
                <VehiclePoolSelect
                  classNamePrefix="vehicle-pool-select"
                  value={props.input.value}
                  onChange={props.input.onChange}
                  options={listerOptions}
                />
              )}
            </Field>
            <Field name="list_requests">
              {props => {
                const handleDeleteSeller = (index: number) => {
                  const valuesCopy = [...(props.input.value ?? [])];
                  valuesCopy.splice(index, 1);
                  props.input.onChange(valuesCopy);
                };
                return (
                  <DropZone disabled={canAddNewVehicles} id={`draftRow-${item.id}`}>
                    <SellerWrapper>
                      <>
                        {props.input.value?.map((v: any, i: number) => {
                          return (
                            <SellerButton
                              deleteAction={() => handleDeleteSeller(i)}
                              key={v.user_id}
                              seller={{
                                id: v.user_id,
                                dealership_name: v.dealership_name,
                                vehiclesRange: v.number_of_vehicles
                              }}
                              status={v.status || 'draft'}
                            />
                          );
                        })}
                        {!canAddNewVehicles && <CreateSellerButton {...props} />}
                      </>
                    </SellerWrapper>
                  </DropZone>
                );
              }}
            </Field>
            <NumberOfVehicles listRequests={values.list_requests} />
            <Field name={'seller_preferable_date'}>
              {props => <DatePicker isEditing value={props.input.value} onChange={props.input.onChange} />}
            </Field>
            <Field name={'notes'}>
              {props => (
                <NotesComponent poolId={-1} isEditable value={props.input.value} onChange={props.input.onChange} />
              )}
            </Field>
            <Field name={'preferable_date'}>
              {props => <DatePicker isEditing value={props.input.value} onChange={props.input.onChange} />}
            </Field>
            <StatusComponent status={item.status} />
            <Actions saveAction={form.submit} deleteAction={hasDeleteAction ? handleDeleteButtonClicked : undefined} />
            {hasDeleteAction && item.status !== 'draft' && (
              <ConfirmDeleteStoreModal id={item.id} handleDelete={() => deleteVehiclePoolRow(item.id)} />
            )}
          </TableRow>
        );
      }}
    </Form>
  );
};
