import { Vehicle } from '../../../../types/vehicle';
import NumberFormat from 'react-number-format';
import { useAdminOfferAuction, useCustomerHoverStateHandlers } from '../hooks';
import styled from 'styled-components';
import { cutTextElipsis } from '../../../../utils';
import { loginAsUser } from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { HoverInfoTooltip } from './HoverInfo';
import { useState } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-auto-rows: 1fr;
  gap: 8px 8px;
  font-weight: 500;
`;

const Label = styled.span`
  color: #757575;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

type Props = {
  vehicle: Vehicle;
};

export const AdminProxyOffers = ({ vehicle }: Props) => {
  const offerAuction = useAdminOfferAuction(vehicle.id);
  const proxyOfferBid = offerAuction?.offerProxyBid;
  const admin = useSelector((state: any) => state?.user?.user);
  const isLister = admin?.job_tile === 'Lister';
  const dispatch = useDispatch();

  const { isOpen, handleClose, handleOpen } = useCustomerHoverStateHandlers();

  const handleClick = () => {
    if (!proxyOfferBid?.bidder?.id || isLister) return;
    dispatch(
      loginAsUser({ id: proxyOfferBid.bidder.id, initialPage: `/admin/auctions/${vehicle.auction_id}` }).request
    );
  };

  return (
    <Wrapper>
      <NumberFormat displayType="text" prefix="$" value={proxyOfferBid?.amount ?? 0} thousandSeparator />

      {proxyOfferBid?.amount && (
        <HoverInfoTooltip
          initialPage={`/admin/auctions/${vehicle.auction_id}`}
          isTooltipOpen={isOpen}
          closeTooltip={handleClose}
          userId={proxyOfferBid.bidder.id}
        >
          <Label onClick={handleOpen}>{`${cutTextElipsis(proxyOfferBid.bidder.dealership_name, 8)}`}</Label>
        </HoverInfoTooltip>
      )}
    </Wrapper>
  );
};
