import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useDispatch, useSelector } from 'react-redux';
import api, {
  RequestsByDay,
  useAddRequestToPoolMutation,
  useGetListerRequestsByDayQuery,
  useListerCreatePoolMutation,
  useSetListRequestOrderMutation
} from 'services/api/list-vehicles';
import moment from 'moment';

export const useHandleDragEnd = () => {
  const dispatch = useDispatch<any>();
  const [setListRequestOrder] = useSetListRequestOrderMutation();
  const user = useSelector((state: any) => state.user.user);
  const { data } = useGetListerRequestsByDayQuery();
  const [createNewPool] = useListerCreatePoolMutation();

  const [addRequestToPool] = useAddRequestToPoolMutation();

  return (e: DragEndEvent) => {
    const { active, over } = e;
    const sortableId = active.data.current?.sortable?.containerId;
    if (!sortableId) return;

    const [, dayNumber] = sortableId.split('-');

    if (!over || !active) return;

    if (typeof over.id === 'string' && over.id.startsWith('NoDataDropZone')) {
      const [, overDayNumber] = over.id.split('-').map(Number);
      const activeListItem = data?.[dayNumber]?.[0]?.listRequests?.find(e => e.id === active.id);

      const startOfTheWeek = moment()
        .locale('en-us')
        .startOf('week');

      const date = startOfTheWeek.add(overDayNumber - 1, 'days');

      dispatch(
        api.util.updateQueryData('getListerRequestsByDay', undefined, draft => {
          if (draft[overDayNumber][0]) {
            draft[overDayNumber][0].listRequests = [activeListItem!];
            addRequestToPool({ poolId: draft[overDayNumber][0].id, requestId: active.id as number });
          } else {
            draft[overDayNumber][0] = {
              id: -1,
              lister_id: user.id,
              listRequests: [activeListItem!]
            };

            createNewPool({
              date: date.format('YYYY-MM-DD'),
              listRequestId: active.id,
              listerId: user.id
            });
          }

          draft[dayNumber][0].listRequests = draft[dayNumber][0].listRequests.filter(e => e.id !== active.id);
        })
      );

      return;
    }

    if (active.id === over.id) return;

    const overSortableId = over.data.current?.sortable?.containerId;
    const [, overDayNumber] = overSortableId.split('-');

    if (dayNumber === overDayNumber) {
      dispatch(
        api.util.updateQueryData('getListerRequestsByDay', undefined, draft => {
          const items = draft[dayNumber]?.[0]?.listRequests;
          if (!items) return;
          const oldIndex = items.findIndex(lr => lr.id === active.id);
          const newIndex = items.findIndex(lr => lr.id === over.id);

          draft[dayNumber][0].listRequests = arrayMove(items, oldIndex, newIndex);

          const newData = [...draft[dayNumber][0].listRequests];

          const orderMap = newData.reduce((acc, curr, i) => {
            return { ...acc, [curr.id]: i };
          }, {} as Record<number, number>);

          setListRequestOrder(orderMap);
        })
      );
    } else {
      const poolId = data?.[overDayNumber]?.[0]?.id;
      if (!poolId) return;

      addRequestToPool({ poolId, requestId: active.id as number });

      dispatch(
        api.util.updateQueryData('getListerRequestsByDay', undefined, draft => {
          const overPool = draft[overDayNumber][0];
          const activePool = draft[dayNumber][0];
          if (!overPool || !activePool) return;
          const overIndex = overPool.listRequests.findIndex(e => e.id === over.id);
          const activeIndex = activePool.listRequests.findIndex(e => e.id === active.id);
          overPool.listRequests.splice(overIndex, 0, activePool.listRequests[activeIndex]);
          activePool.listRequests.splice(activeIndex, 1);

          const overOrderMap = getOrderMap(overPool.listRequests);
          const activeOrderMap = getOrderMap(activePool.listRequests);

          setListRequestOrder({ ...overOrderMap, ...activeOrderMap });
        })
      );
    }
  };
};

const getOrderMap = (listRequests: RequestsByDay[]) => {
  return listRequests.reduce((acc, curr, i) => {
    return { ...acc, [curr.id]: i };
  }, {} as Record<number, number>);
};
