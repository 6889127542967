import { createEntityAdapter } from '@reduxjs/toolkit';
import { Vehicle } from '../../../types/vehicle';
import apiSlice from '..';
import { EntityWithCount } from '../types';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';

const allBuyerTransactionsAdapter = createEntityAdapter<Vehicle>();
const easyPayBuyerTransactionsAdapter = createEntityAdapter<Vehicle>();
const cardBuyerTransactionsAdapter = createEntityAdapter<Vehicle>();

export const offerAuctionApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllBuyerTransactions: builder.query<EntityWithCount<Vehicle>, { limit: number; offset: number }>({
      query: ({ limit, offset }) => ({
        method: 'GET',
        url: `buyer/awarded-vehicles`,
        params: { limit, offset }
      }),
      transformResponse: (response: any) => {
        return {
          rows: allBuyerTransactionsAdapter.addMany(allBuyerTransactionsAdapter.getInitialState(), response.data.rows),
          count: Number(response.data.count)
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...restArgs } = queryArgs;
        return defaultSerializeQueryArgs({ queryArgs: restArgs, endpointDefinition, endpointName });
      },
      merge: (currentCache, newItems, { arg }) => {
        const newVehicles = (newItems.rows?.ids?.map(el => newItems.rows.entities[el]) ?? []) as Vehicle[];
        currentCache.count = newItems.count;
        if (arg.offset === 1) {
          allBuyerTransactionsAdapter.setAll(currentCache.rows, newVehicles);
        } else {
          allBuyerTransactionsAdapter.addMany(currentCache.rows, newVehicles);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      }
    }),
    getEasyPayBuyerTransactions: builder.query<EntityWithCount<Vehicle>, { limit: number; offset: number } & Record<string, any>>({
      query: ({ limit, offset, ...filters }) => ({
        method: 'GET',
        url: `buyer/easy-pay`,
        params: { limit, offset, ...filters }
      }),
      transformResponse: (response: any) => {
        return {
          rows: easyPayBuyerTransactionsAdapter.addMany(
            easyPayBuyerTransactionsAdapter.getInitialState(),
            response.data.rows
          ),
          count: Number(response.data.count)
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...restArgs } = queryArgs;
        return defaultSerializeQueryArgs({ queryArgs: restArgs, endpointDefinition, endpointName });
      },
      merge: (currentCache, newItems, { arg }) => {
        const newVehicles = (newItems.rows?.ids?.map(el => newItems.rows.entities[el]) ?? []) as Vehicle[];
        currentCache.count = newItems.count;
        if (arg.offset === 1) {
          easyPayBuyerTransactionsAdapter.setAll(currentCache.rows, newVehicles);
        } else {
          easyPayBuyerTransactionsAdapter.addMany(currentCache.rows, newVehicles);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      }
    }),
    getCardBuyerTransactions: builder.query<EntityWithCount<Vehicle>, { limit: number; offset: number } & Record<string, any>>({
      query: ({ limit, offset, ...filters }) => ({
        method: 'GET',
        url: `financials/transactions`,
        params: { limit, offset, ...filters }
      }),
      transformResponse: (response: any) => {
        return {
          rows: cardBuyerTransactionsAdapter.addMany(
            cardBuyerTransactionsAdapter.getInitialState(),
            response.data.rows
          ),
          count: Number(response.data.count)
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...restArgs } = queryArgs;
        return defaultSerializeQueryArgs({ queryArgs: restArgs, endpointDefinition, endpointName });
      },
      merge: (currentCache, newItems, { arg }) => {
        const newVehicles = (newItems.rows?.ids?.map(el => newItems.rows.entities[el]) ?? []) as Vehicle[];
        currentCache.count = newItems.count;
        if (arg.offset === 1) {
          cardBuyerTransactionsAdapter.setAll(currentCache.rows, newVehicles);
        } else {
          cardBuyerTransactionsAdapter.addMany(currentCache.rows, newVehicles);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      }
    })
  }),
  overrideExisting: true
});

export const {
  useGetAllBuyerTransactionsQuery,
  useGetEasyPayBuyerTransactionsQuery,
  useGetCardBuyerTransactionsQuery
} = offerAuctionApiSlice;
