import { call, put, takeLatest, all } from 'redux-saga/effects';
import { goBack, push } from 'connected-react-router';
import {
  awardBuyer,
  pullVehicle,
  deactivateVehicle,
  sellerAcceptBid,
  adminApproveBid,
  sellerSetCounter,
  adminSetCounter,
  buyerAcceptCounter,
  modalsToggle,
  relistVehicle,
  modalsHide,
  snackShow
} from '../../actions';
import {
  AUCTION_DETAIL_AWARD_BUYER_REQUEST,
  unawardVehicle,
  auctionDetailAwardBuyer,
  DELETE_VEHICLE_REQUEST,
  deleteVehicle,
  AUCTION_DETAIL_SET_COUNTER_REQUEST,
  auctionDetailSetCounter,
  EASY_PAY_BUY_REQUEST,
  easyPayBuyVehicle
} from '../../actions/vehicles';
import {
  VEHICLE_AWARD_BUYER_REQUEST,
  VEHICLE_UNAWARD_REQUEST,
  VEHICLE_PULL_REQUEST,
  VEHICLE_RELIST_REQUEST,
  VEHICLE_DEACTIVATE_REQUEST,
  VEHICLE_SELLER_ACCEPT_BID_REQUEST,
  VEHICLE_ADMIN_APPROVE_BID_REQUEST,
  VEHICLE_SELLER_SET_COUNTER_REQUEST,
  VEHICLE_ADMIN_SET_COUNTER_REQUEST,
  VEHICLE_BUYER_ACCEPT_COUNTER_REQUEST
} from '../../constants/actionTypes';
import { Api } from '../../utils';

function* auctionDetailSetCounterSaga({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.adminSetCounter, payload.vehicle_id, { amount: payload.amount });
    yield put(modalsToggle('setCounterModal'));
    yield put(auctionDetailSetCounter(data).success);
  } catch (e) {
    yield put(auctionDetailSetCounter(e).failure);
  }
}

function* removeVehicle({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.deleteVehicle, payload);
    yield put(deleteVehicle(data).success);
    yield put(snackShow({ message: 'Vehicle successfuly deleted' }));
    yield put(push('/'));
    yield put(modalsHide());
  } catch (e) {
    yield put(deleteVehicle(e).failure);
    yield put(snackShow({ message: 'Failed to delete vehicle', type: 'error' }));
    yield put(modalsHide());
  }
}

function* auctionDetailAwardBuyerSaga({ payload }) {
  try {
    const { vehicle_id, ...body } = payload;
    const { data } = yield call(Api.vehicles.awardBuyer, vehicle_id, body);
    yield put(snackShow({ message: 'Vehicle successfully awarded to buyer' }));
    yield put(auctionDetailAwardBuyer(data).success);
  } catch (e) {
    yield put(
      snackShow({
        message: 'Failed to award vehicle to buyer. Please try again later',
        type: 'error'
      })
    );
    yield put(auctionDetailAwardBuyer(e).failure);
  }
}

function* awardVehicleBuyer({ payload }) {
  try {
    const { vehicle_id, ...body } = payload;
    const { data } = yield call(Api.vehicles.awardBuyer, vehicle_id, body);
    yield put(snackShow({ message: 'Vehicle successfully awarded to buyer' }));
    yield put(awardBuyer(data).success);
  } catch (e) {
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
    yield put(awardBuyer(e.response.data).failure);
  }
}

function* vehicleUnaward({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.unawardVehicle, payload.vehicle_id);
    yield put(unawardVehicle(data).success);
  } catch (e) {
    yield put(pullVehicle(e).failure);
  }
}

function* pullVehicleFromAuction({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.pullVehicle, payload.vehicle_id);
    yield put(pullVehicle(data).success);
    yield put(
      snackShow({
        message: 'The vehicle was pulled',
        type: 'error'
      })
    );
  } catch (e) {
    yield put(pullVehicle(e).failure);
  }
}

function* relistVehicleToAuction({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.relistVehicle, payload.vehicle_id, { ...payload });
    yield put(relistVehicle(data).success);
    yield put(
      snackShow({
        message: payload.message || 'Vehicle re-listed successfuly'
      })
    );
  } catch (e) {
    yield put(relistVehicle(e).failure);
  }
}

function* deactivateAuctionVehicle({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.deactivateVehicle, payload.vehicle_id);
    yield put(deactivateVehicle(data).success);
  } catch (e) {
    yield put(deactivateVehicle(e).failure);
  }
}

function* sellerAcceptHighestBid({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.acceptBid, payload.vehicle_id);
    yield put(sellerAcceptBid(data).success);
    yield put(modalsToggle('acceptHighestBidModal'));
  } catch (e) {
    yield put(sellerAcceptBid(e).failure);
  }
}

function* adminApprovetHighestBid({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.approveBid, payload);
    yield put(snackShow({ message: 'Buyer successfuly charged. Vehicle awarded' }));
    yield put(adminApproveBid(data).success);
  } catch (e) {
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
    yield put(adminApproveBid(e.response.data).failure);
  }
}

function* sellerSetCounterBid({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.sellerSetCounter, payload.vehicle_id, { amount: payload.amount });
    yield put(modalsToggle('setCounterModal'));
    yield put(sellerSetCounter(data).success);
  } catch (e) {
    yield put(sellerSetCounter(e).failure);
  }
}

function* adminSetCounterBid({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.adminSetCounter, payload.vehicle_id, { amount: payload.amount });
    yield put(adminSetCounter(data).success);
  } catch (e) {
    yield put(adminSetCounter(e).failure);
  }
}

function* buyerAcceptCounterBid({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.buyerAcceptCounter, payload.vehicle_id);
    yield put(buyerAcceptCounter(data).success);
  } catch (e) {
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
    yield put(buyerAcceptCounter(e).failure);
  }
}

function* buyerBuyVehicleUsingEasyPay({ payload }) {
  try {
    const { data: { id, ...data }, resolve } = payload;
    const { data: responseData } = yield call(Api.vehicles.purchaseVehicleUsingEasyPay, id, data);
    yield put(easyPayBuyVehicle(responseData).success);
    yield put(snackShow({ message: responseData.message, type: 'success' }));
    yield put(goBack());
    yield call(resolve);
  } catch (e) {
    const { reject } = payload;
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
    yield put(easyPayBuyVehicle(e).failure);
    yield call(reject);
  }
}

export default function*() {
  yield all([
    takeLatest(AUCTION_DETAIL_SET_COUNTER_REQUEST, auctionDetailSetCounterSaga),
    takeLatest(VEHICLE_AWARD_BUYER_REQUEST, awardVehicleBuyer),
    takeLatest(DELETE_VEHICLE_REQUEST, removeVehicle),
    takeLatest(AUCTION_DETAIL_AWARD_BUYER_REQUEST, auctionDetailAwardBuyerSaga),
    takeLatest(VEHICLE_UNAWARD_REQUEST, vehicleUnaward),
    takeLatest(VEHICLE_PULL_REQUEST, pullVehicleFromAuction),
    takeLatest(VEHICLE_RELIST_REQUEST, relistVehicleToAuction),
    takeLatest(VEHICLE_DEACTIVATE_REQUEST, deactivateAuctionVehicle),
    takeLatest(VEHICLE_SELLER_ACCEPT_BID_REQUEST, sellerAcceptHighestBid),
    takeLatest(VEHICLE_ADMIN_APPROVE_BID_REQUEST, adminApprovetHighestBid),
    takeLatest(VEHICLE_SELLER_SET_COUNTER_REQUEST, sellerSetCounterBid),
    takeLatest(VEHICLE_ADMIN_SET_COUNTER_REQUEST, adminSetCounterBid),
    takeLatest(VEHICLE_BUYER_ACCEPT_COUNTER_REQUEST, buyerAcceptCounterBid),
    takeLatest(EASY_PAY_BUY_REQUEST, buyerBuyVehicleUsingEasyPay)
  ]);
}
