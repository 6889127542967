import React from 'react';
import styled from 'styled-components';
import { Grid, MenuItem, Button } from '@material-ui/core';
import { compose } from 'redux';
import { push } from 'connected-react-router';
import { connect, useDispatch } from 'react-redux';
import { getFormValues, reduxForm, Field } from 'redux-form';
import { FormTextField } from '../../../../components/Form';
import { AdditionalFilters, VerticalFilter } from '../../../../containers';
import { ADMIN_VEHICLE_LIST_DYNAMIC_FILTERS } from '../../../../constants/filters';
import { filterVehiclesAdmin, vehiclesSetPage } from '../../../../actions/vehicles';
import { debounce } from '../../../../utils';
import { openVehiclePoolModal } from '../../../../services/listVehiclesSlice/listVehiclesSlice';
import VehiclePoolDialog from 'components/ListVehicles/VehiclePoolDialog';

const StyledButton = styled(Button)`
  h2 {
    color: white;
    font-weight: 500;
    font-size: 16px;
  }
`;

const toUpperCase = value => value && value.toUpperCase();

const debounceOnChange = debounce((values, dispatch, { perPage, setPage }) => {
  setPage(1);
  dispatch(filterVehiclesAdmin({ params: { limit: perPage, offset: 1 } }).request);
  setPage(2);
}, 1000);

const formName = 'vehiclesAdminFilterForm';

const Filter = ({ filterCount, history, reset, dirty }) => {
  const dispatch = useDispatch();
  return (
    <Grid container justify="flex-end" spacing={8}>
      <Grid item>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(openVehiclePoolModal())
          }}
        >
          Vehicle pool
        </StyledButton>
        <VehiclePoolDialog />
      </Grid>
      <Grid item>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => {
            window.location = '/vehicles/add';
          }}
        >
          Upload Vehicle
        </StyledButton>
      </Grid>
      <Grid item>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/vehicles/add-manual');
          }}
        >
          Manual Upload
        </StyledButton>
      </Grid>
      <Grid item>
        <VerticalFilter filterCount={filterCount}>
          <Field component={FormTextField} name="vin" label="VIN Search" normalize={toUpperCase} />
          <Field component={FormTextField} name="dealership_name" label="Seller Search" />
          <Field component={FormTextField} name="status" label="Filter by Vehicle State" select>
            <MenuItem value="">All</MenuItem>
            <MenuItem value="awarded">Awarded</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="pulled">Pulled</MenuItem>
            <MenuItem value="draft">Draft</MenuItem>
            <MenuItem value="deactivated">Deactivated</MenuItem>
          </Field>
          <Field component={FormTextField} name="auction_type" label="Filter by Auction" select>
            <MenuItem value="">All</MenuItem>
            <MenuItem value="autoaxess">Autoaxess</MenuItem>
            <MenuItem value="frontline">Frontline</MenuItem>
          </Field>
          <Field component={FormTextField} name="order_by" label="Sort By" select>
            <MenuItem value="default">Most Recent</MenuItem>
            <MenuItem value="by_seller">By Seller</MenuItem>
          </Field>
          <AdditionalFilters filters={ADMIN_VEHICLE_LIST_DYNAMIC_FILTERS} formName={formName} />
          {dirty && (
            <Button onClick={reset} variant="contained" color="primary">
              Reset
            </Button>
          )}
        </VerticalFilter>
      </Grid>
    </Grid>
  );
};

const formDefaultOperators = ADMIN_VEHICLE_LIST_DYNAMIC_FILTERS.reduce((acc, filter) => {
  if (!filter.operators) return acc;
  return { ...acc, [`${filter.name}_operator`]: 'eq' };
}, {});

const mapStateToProps = state => {
  const filterCount = Object.keys(getFormValues(formName)(state) || {}).reduce((acc, filterKey) => {
    if (formDefaultOperators[filterKey] || filterKey === 'order_by') return acc;
    return acc + 1;
  }, 0);
  return {
    page: state.vehicles.page,
    perPage: state.vehicles.perPage,
    filterCount
  };
};

const mapDispatchToProps = dispatch => ({
  setPage: page => dispatch(vehiclesSetPage(page)),
  navigateToVehicleCreation: () => dispatch(push('/vehicles/add'))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    onChange: debounceOnChange,
    initialValues: {
      order_by: 'default',
      ...formDefaultOperators
    },
    mapStateToProps,
    destroyOnUnmount: false
  })
)(Filter);
