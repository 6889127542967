import styled from 'styled-components';
import { getStatusColor } from './ListVehiclesRow/StatusComponent';
import { Icon } from '@material-ui/core';
import { UniqueIdentifier, useDraggable } from '@dnd-kit/core';
import { ActiveItem } from '../../../services/listVehiclesSlice/listVehiclesSlice';
import { useState } from 'react';
import { EditNumberOfVehiclesForm } from './EditNumberOfVehiclesForm';

type Props = {
  dragData?: ActiveItem;
  seller: {
    id: number;
    dealership_name: string;
    vehiclesRange?: string;
  };
  id?: UniqueIdentifier;
  status: string;
  deleteAction?: any;
  clickAction?: any;
  isActive?: boolean;
  canEditNumberOfVehicles?: boolean;
  requestId?: number;
};

export const RoundedLabel = styled.div<{ isDragging?: boolean; color: string; background: string; isActive: boolean }>`
  color: ${props => props.color};
  background: ${props => props.background};
  border-radius: 8px;
  padding: 8px 12px;
  max-width: fit-content;
  font-size: 10px;
  font-weight: 400;
  border: 1px solid ${props => (props.isActive ? '#B6B1B1' : props.background)};
  cursor: pointer;
  position: relative;
  opacity: ${props => (props.isDragging ? 0.5 : 1)};
`;

export const VehicleRangeWrapper = styled.div`
  position: absolute;
  background: #b6b1b1;
  color: #fff;
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 2px 6px;
  text-align: center;
  top: -8px;
  right: -8px;
  font-weight: 700;
`;

const ClickTopLeftActionWrapper = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  background: red;
  top: -5px;
  right: -4px;
  opacity: 0;
`;

const DeleteWrapper = styled.div`
  position: absolute;
  background: #b6b1b1;
  color: #fff;
  border: 1px solid #ffffff;
  border-radius: 100%;
  top: -8px;
  cursor: pointer;

  right: -8px;

  display: flex;
  align-items: center;
  padding: 2px;

  & > span {
    font-size: 10px;
  }
`;

export const SellerButton = ({
  seller,
  status,
  deleteAction,
  id = -1,
  isActive = false,
  requestId,
  dragData,
  canEditNumberOfVehicles = false,
  clickAction = () => {}
}: Props) => {
  const { color, background } = getStatusColor(status);
  const [vehicleFormVisible, setVehicleFormVisible] = useState(false);
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id,
    disabled: id === -1,
    data: dragData
  });

  const handleVehicleRangeClicked = (e: any) => {
    if (!canEditNumberOfVehicles) return;
    e.preventDefault();
    setVehicleFormVisible(true);
  };

  return (
    <RoundedLabel
      onClick={clickAction}
      isActive={isActive}
      isDragging={isDragging}
      color={color}
      background={background}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
    >
      <span>{seller.dealership_name}</span>
      {seller.vehiclesRange && !deleteAction && !vehicleFormVisible && (
        <VehicleRangeWrapper onClick={handleVehicleRangeClicked}>{seller.vehiclesRange}</VehicleRangeWrapper>
      )}
      {!seller.vehiclesRange && !deleteAction && !vehicleFormVisible && canEditNumberOfVehicles && (
        <ClickTopLeftActionWrapper onClick={handleVehicleRangeClicked} />
      )}
      {vehicleFormVisible && (
        <EditNumberOfVehiclesForm
          id={requestId!}
          initialValue={seller.vehiclesRange}
          closeForm={() => setVehicleFormVisible(false)}
        />
      )}
      {deleteAction && !vehicleFormVisible && (
        <DeleteWrapper id="delete-action" onClick={deleteAction}>
          <Icon>close</Icon>
        </DeleteWrapper>
      )}
    </RoundedLabel>
  );
};
