import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  closeVehiclePoolModal,
  toggleRightPanel,
  useGetDraggableItem,
  useIsPoolModalOpen,
  useIsRightPanelExpanded
} from '../../services/listVehiclesSlice/listVehiclesSlice';
import { ListVehiclesRow } from './components/ListVehiclesRow';
import { VehiclePoolFilter } from './components/Filters/VehiclePoolFIlter';
import { VehicleRowForm } from './components/ListVehiclesRow/VehicleRowForm';
import {
  useDeleteDraftVehiclePoolRowsMutation,
  useFilteredGetVehiclesPoolQuery,
  useGetVehiclesPoolsQuery
} from '../../services/api/list-vehicles';
import { ExpandLeftIcon } from '../Icons/ExpandLeft';
import { ExpandRightIcon } from '../Icons/ExpandRight';
import { RightPanel } from './components/RightPanel';
import { DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useRef, useState } from 'react';
import { ActiveDragItem } from './ActiveDragItem';
import { useHandleDragStart } from './hooks/useHandleDragStart';
import { useHandleDragEnd } from './hooks/useHandleDragEnd';
import { VehiclePoolTableHeader } from './components/VehiclePoolTableHeader';
import { AdminMap } from './components/AdminMap';

const StyledDialog = styled(Dialog)`
  .vehicle-pool-paper {
    min-height: calc(100% - 96px);
    max-height: calc(100% - 96px);
    min-width: 1280px;
    width: calc(100vw - 20%);
    max-width: unset;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  position: relative;
  min-height: calc(100% - 96px);
`;

const ExpandPanelButton = styled.div`
  position: absolute;
  right: 0;
  border-radius: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #fff;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  z-index: 20;
  transform: translateY(-50%);

  // & svg {
  //   position: sticky;
  // }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  height: calc(100vh - 96px);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4px;
  width: 100%;
  overflow-y: auto;
  flex-grow: 1;
  scroll-behavior: smooth;
`;

const VehiclePoolDialog = () => {
  const isOpen = useIsPoolModalOpen();
  const dispatch = useDispatch();

  const [deleteDraftRows] = useDeleteDraftVehiclePoolRowsMutation();

  const ref = useRef<any>(null);

  const rightPanelExpanded = useIsRightPanelExpanded();

  const close = () => {
    dispatch(closeVehiclePoolModal());
    deleteDraftRows();
  };
  const handleExpandIconClick = () => {
    dispatch(toggleRightPanel());
  };

  const { data: submittedRows } = useFilteredGetVehiclesPoolQuery({ skip: !isOpen });

  const activeItem = useGetDraggableItem();

  const handleDragStart = useHandleDragStart();
  const handleDragEnd = useHandleDragEnd();

  const touchSensor = useSensor(TouchSensor);
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 5
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  return (
    <StyledDialog
      classes={{ paperScrollPaper: 'vehicle-pool-paper' }}
      open={isOpen}
      onBackdropClick={close}
      fullWidth
      maxWidth={'lg'}
    >
      <DndContext sensors={sensors} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <PageWrapper>
          <VehiclePoolFilter />
          <AdminMap />
          <TableWrapper>
            <ExpandPanelButton onClick={handleExpandIconClick}>
              {rightPanelExpanded ? <ExpandLeftIcon /> : <ExpandRightIcon />}
            </ExpandPanelButton>
            <VehiclePoolTableHeader />
            <ContentWrapper ref={ref}>
              <RightPanel />
              {submittedRows?.map(el => {
                if (el.status === 'draft' || el.actionStatus === 'editing') {
                  return <VehicleRowForm item={el} key={el.id} />;
                }
                return <ListVehiclesRow item={el} key={el.id} />;
              })}
            </ContentWrapper>
          </TableWrapper>
        </PageWrapper>
        <DragOverlay>{activeItem ? <ActiveDragItem item={activeItem} /> : null}</DragOverlay>
      </DndContext>
    </StyledDialog>
  );
};

export default VehiclePoolDialog;
