import { FormattedAmount } from '../../../../../components';
import { StyledLabel, StyledText, Wrapper } from './styled';
import { BlockProps } from './types';
import { Timer } from '../../../../../components';
import HighestBidder from '../HighestBidder';
import { useSelector } from 'react-redux';
import { Seller } from '../../../../Admin/AuctionDetail/components';
import BidStatus from '../BidStatus';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { getHighestBid } from '../../../../../utils/vehicle';
import { useTimerConfig } from '../../../../../hooks';
import { OfferStatus } from '../../../../../types/vehicle';
import { useBidsOfferAuction } from '../hooks';

export const VehicleBidInfoBlock = ({ currentVehicle }: BlockProps) => {
  const user = useSelector((state: any) => state.user?.user);
  const offerAuction = useBidsOfferAuction(currentVehicle.id);
  const status = auctionOfferStatuses(currentVehicle, offerAuction, user);

  const counterOffer = offerAuction?.privateOffers?.find(
    val => val.sender_id !== user?.id && val.status === OfferStatus.SENT
  );

  const sentOffer = offerAuction?.privateOffers?.find(
    val => val.sender_id === user?.id && val.status === OfferStatus.SENT
  );

  const { timer, colorsConfig } = useTimerConfig(currentVehicle, offerAuction);

  const highestBid = getHighestBid(currentVehicle);
  const myBid = currentVehicle.my_bid;

  const isTopBidder =
    currentVehicle.status === 'awarded' ? currentVehicle.buyer_id === user.id : user.id === highestBid.user_id;

  const displayHighestBid = (() => {
    if (currentVehicle.status === 'awarded') {
      console.log(currentVehicle.awarded_user, 'awarded dealer');
      return {
        bidder: {
          dealership_name: currentVehicle.awarded_user?.dealership_name,
          id: currentVehicle.awarded_user?.id
        },
        amount: currentVehicle.vehicle_price
      };
    }
    return isTopBidder ? { ...highestBid, amount: currentVehicle.proxy_bid?.amount || 0 } : highestBid;
  })();

  return (
    <div>
      <Wrapper>
        <StyledLabel>Time Left:</StyledLabel>
        <StyledText>
          {timer != null ? <Timer withColors colorsConfig={colorsConfig} toDate={timer} /> : 'N/A'}
        </StyledText>
      </Wrapper>
      <Wrapper>
        <StyledLabel>Your Bid:</StyledLabel>
        <StyledText>{myBid?.amount ? FormattedAmount({ amount: myBid.amount }) : 'N/A'}</StyledText>
      </Wrapper>
      <Wrapper>
        <StyledLabel>High Bid:</StyledLabel>
        <StyledText>
          <HighestBidder highestBid={displayHighestBid} currentUser={user} />
        </StyledText>
      </Wrapper>
      <Wrapper>
        <StyledLabel>Seller:</StyledLabel>
        <StyledText>
          <Seller name={currentVehicle.user.dealership_name} seller={currentVehicle.user.seller} />
        </StyledText>
      </Wrapper>
      <Wrapper>
        <StyledLabel>Status:</StyledLabel>
        <StyledText>
          <BidStatus
            vehicle={currentVehicle}
            currentUser={user}
            counterAmount={sentOffer?.amount ?? counterOffer?.amount}
            status={status}
            offerAuction={offerAuction}
          />
        </StyledText>
      </Wrapper>
    </div>
  );
};
