import { StyledLabel, Wrapper } from './styled';
import { BlockProps } from './types';
import { FormattedAmount } from '../../../../../components';
import { ReservePriceLabel, VehicleSoldLabel } from '../../../../../components/ReservePriceMet';
import { CurrentBidComponent } from '../../../../../components/Typography';
import styled from 'styled-components';
import { useBidsOfferAuction } from '../hooks';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { OfferAuction, OfferAuctionStatus } from '../../../../../types/vehicle';
import { VEHICLE_STATUSES } from '../../../../../constants';
import HighestBidder from '../HighestBidder';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1px;
`;

const HighOfferContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const BoldText = styled.span`
  color: #000;
  font-size: 12px;
  font-weight: 500;
`;

type BlockInfoProps = BlockProps & {
  offerAuction?: OfferAuction | null;
};

type DisplayInfo = {
  bidInfo: [string, number];
  additionalInfo: [string, number | JSX.Element][];
  vehicleTimeEnded: boolean;
  isAwarded?: boolean;
};

const useGetDisplayInfo = ({ currentVehicle, offerAuction }: BlockInfoProps): DisplayInfo => {
  const currentTime = moment.utc();
  const isVehicleTimerEnded = currentVehicle.date_end && moment.utc(currentVehicle.date_end).isBefore(currentTime);
  const user = useSelector((state: any) => state.user.user);

  const isTopBidder = currentVehicle.proxy_bid && currentVehicle.proxy_bid.user_id === user?.id;

  const displayHighestBid = (() => {
    if (currentVehicle.status === 'awarded') {
      console.log(currentVehicle.awarded_user, 'awarded dealer');
      return {
        bidder: {
          dealership_name: currentVehicle.awarded_user?.dealership_name,
          id: currentVehicle.awarded_user?.id
        },
        amount: currentVehicle.vehicle_price
      };
    }

    return currentVehicle.highest_bid;
  })();

  if (!isVehicleTimerEnded || !offerAuction) {
    return {
      bidInfo: ['Current bid:', currentVehicle.highest_bid?.amount || 0],
      additionalInfo: [
        ['Your Bid:', currentVehicle.my_bid?.amount || 0],
        [
          'High Bid:',
          <HighestBidder
            hideNameForTopBuyer
            cutText={isTopBidder ? 6 : 12}
            highestBid={displayHighestBid}
            currentUser={user}
          />
        ],
        ['Your Proxy Bid:', isTopBidder ? currentVehicle.proxy_bid.amount : 0]
      ],
      vehicleTimeEnded: false,
      isAwarded: currentVehicle?.status === VEHICLE_STATUSES.AWARDED
    };
  }

  if (offerAuction.status === OfferAuctionStatus.PRIVATE_BIDS && offerAuction.top_bidder_id === user.id) {
    const sellerCounter = offerAuction.privateOffers?.find(el => el.sender_id !== user.id);
    const myCounter = offerAuction.privateOffers?.find(el => el.sender_id === user.id);
    const bidInfo: [string, number] = ['Current bid:', currentVehicle.highest_bid?.amount || 0];
    const sellerCounterInfo: [string, number] = ['Seller Counter:', sellerCounter?.amount || 0];
    const myCounterInfo: [string, number] = ['Your Counter:', myCounter?.amount || 0];
    if (sellerCounter) {
      return {
        bidInfo: sellerCounterInfo,
        additionalInfo: [myCounterInfo],
        vehicleTimeEnded: true
      };
    }
    return {
      bidInfo,
      additionalInfo: [sellerCounterInfo, myCounterInfo],
      vehicleTimeEnded: true
    };
  }

  const adminPublicCounter = offerAuction?.privateOffers?.find(offer => offer.type === 'public' && offer.is_admin);
  const myOffer = offerAuction?.offerBids?.find(el => el.user_id === user?.id);
  const highOffer = offerAuction?.offerBids?.[0];
  const proxyOffer = offerAuction?.offerProxyBid;

  const isMyProxy = offerAuction?.offerProxyBid?.user_id === user.id;
  const highOfferAmount = (() => {
    if (currentVehicle.status === 'awarded') {
      return currentVehicle.vehicle_price;
    }

    return proxyOffer && isMyProxy ? proxyOffer?.amount : highOffer?.amount || 0;
  })();
  return {
    bidInfo: ['Current Offer:', highOffer?.amount || 0],
    additionalInfo: [
      ['Seller Counter:', adminPublicCounter?.amount || 0],
      ['Your Offer:', myOffer?.amount || 0],
      [
        'High Offer:',
        <HighOfferContainer>
          <FormattedAmount amount={highOfferAmount} />
          <span>/</span>
          <span>
            {isMyProxy || currentVehicle.buyer_id === user.id
              ? 'You'
              : (proxyOffer?.bidder?.dealership_name ?? '').slice(0, 4)}
          </span>
        </HighOfferContainer>
      ]
    ],
    vehicleTimeEnded: true
  };
};

export const MyBidsCurrentBidBlock = ({ currentVehicle }: BlockProps) => {
  const offerAuction = useBidsOfferAuction(currentVehicle.id);

  const { bidInfo, additionalInfo, vehicleTimeEnded, isAwarded } = useGetDisplayInfo({ currentVehicle, offerAuction });

  const [label, amount] = bidInfo;

  return (
    <Container>
      <Wrapper justifyBetween>
        <StyledLabel isBold>{label}</StyledLabel>
        {amount ? <CurrentBidComponent amount={amount} /> : 'N/A'}
      </Wrapper>
      {additionalInfo.map(([label, amount]) => (
        <Wrapper justifyBetween>
          <StyledLabel>{label}</StyledLabel>
          {typeof amount === 'number' ? (
            <BoldText>{amount ? <FormattedAmount amount={amount} /> : '-'}</BoldText>
          ) : (
            <BoldText>{amount}</BoldText>
          )}
        </Wrapper>
      ))}
      {vehicleTimeEnded || isAwarded ? (
        <VehicleSoldLabel activeVehicle={currentVehicle} />
      ) : (
        <ReservePriceLabel activeVehicle={currentVehicle} />
      )}
    </Container>
  );
};
