import { useState } from 'react';
import styled from 'styled-components';
import { OfferHistory } from '../../../../components/Offers';
import { Tooltip, withStyles } from '@material-ui/core';
import { useAdminOfferAuction } from '../hooks';
import { formatCurrency } from '../../../../utils';
import { AdminReservePrice } from './AdminReservePrice';

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  gap: 4px;
`;

const CounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 500;

  & > span {
    font-size: 13px;
    white-space: nowrap;
  }
`;

const styles = () => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#616161',
    fontSize: 14,
    opacity: 1,
    width: 400,
    maxWidth: '100vw',
    marginTop: 6
  },
  popper: {
    backgroundColor: '#ffffff',
    opacity: 1,
    wordBreak: 'break-word',
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    top: '20px !important'
  }
});

const NotesWrapper = styled.div``;

export const AdminCounterAmount = withStyles(styles as any)(({ vehicle, classes }: any) => {
  const [notesActive, setNotesActive] = useState(false);
  const offerAuction = useAdminOfferAuction(vehicle.id);

  const hasAtLeastOneCounter = offerAuction?.privateOffers && offerAuction.privateOffers.length > 0;

  const openNotes = () => {
    if (!hasAtLeastOneCounter) return;
    setNotesActive(true);
  };

  const closeNotes = () => setNotesActive(false);

  const firstCounterRow = (() => {
    const adminPublicCounter = offerAuction?.privateOffers?.find(el => el.is_admin && el.type === 'public');

    return `AA - ${formatCurrency(adminPublicCounter?.amount ?? 0)}`;
  })();

  const secondCounterRow = (() => {
    const publicSellerCounter = offerAuction?.privateOffers?.find(el => !el.is_admin && el.type === 'public');

    if (publicSellerCounter) {
      return `S - ${formatCurrency(publicSellerCounter.amount)}`;
    }
    const lastNonBuyerPrivateCounter = offerAuction?.privateOffers?.find(
      el => el.sender.role !== 'buyer' && el.type === 'private'
    );
    let prefix = 'S';

    if (lastNonBuyerPrivateCounter && lastNonBuyerPrivateCounter.is_admin) {
      prefix = 'AA';
    }

    return `${prefix} - ${formatCurrency(lastNonBuyerPrivateCounter?.amount ?? 0)}`;
  })();

  const buyerCounter = offerAuction?.privateOffers?.find(el => el.sender.role === 'buyer')?.amount;

  const thirdCounterRow = `B - ${formatCurrency(buyerCounter ?? 0)}`;

  return (
    <Wrapper>
      <Tooltip
        classes={{ tooltip: classes.tooltip, popper: classes.popper }}
        disableHoverListener={!hasAtLeastOneCounter}
        disableFocusListener
        disableTouchListener
        open={notesActive}
        title={
          <NotesWrapper>
            <OfferHistory cutNames offerAuction={offerAuction!} />
          </NotesWrapper>
        }
      >
        <CounterWrapper onMouseOver={openNotes} onMouseOut={closeNotes}>
          <span>{firstCounterRow}</span>
          <span>{secondCounterRow}</span>
          <span>{thirdCounterRow}</span>
        </CounterWrapper>
      </Tooltip>
      <AdminReservePrice vehicle={vehicle} />
    </Wrapper>
  );
});
