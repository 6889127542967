import { User } from '../../../../types/user';

export type ListVehiclesRequest = {
  id: number;
  number_of_vehicles?: string;
  notes?: string;
  preferable_date?: string;
  status: string;
  user: {
    id: number;
    dealership_name: string;
    city: string;
    street: string;
    zip: string;
    state: string;
  };
  list_vehicle_pool_id?: number;
};

export type ListVehiclePoolRowFromServer = {
  id: number;
  lister: {
    id: number;
    first_name: number;
  } | null;
  lister_id: number;
  listRequests: ListVehiclesRequest[];
  preferable_date: string | null;
  seller_preferable_date: string | null;
  status: string;
  notes?: string;
  actionStatus: 'idle' | 'editing';
  is_scheduled?: boolean;
};

export enum ListVehiclePoolStatus {
  Draft = 'draft',
  NewListing = 'new_listing',
  Submitted = 'submitted',
  Done = 'done'
}
