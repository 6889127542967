import { Popover } from '@material-ui/core';
import styled from 'styled-components';
import { getLabelFromDayNumber } from '../utils/formatDate';
import { useState } from 'react';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: absolute;
  z-index: 40;
  top: 0;
  height: 60px;
`;

// margin-bottom: ${props => (props.withBottomMargin ? '8px' : 0)};

const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
`;

const Block = styled.div<{ isActive?: boolean }>`
  display: flex;
  gap: 6px;
  width: 150px;
  justify-content: center;
  align-items: center;
  height: 32px;
  background: ${props => (props.isActive ? '#D9D9D966' : 'transparent')};
  border-radius: ${props => (props.isActive ? '8px' : '0')};
`;

const PopoverWrapper = styled.div`
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const PopoverTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

const PopoverOption = styled.div<{ isActive: boolean }>`
  font-size: 14px;
  font-weight: ${props => (props.isActive ? '700' : '400')};
  color: ${props => (props.isActive ? '#000' : '#757575')};
  line-height: 24px;
  cursor: pointer;
`;

const CalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V3C6 2.71667 6.09583 2.47917 6.2875 2.2875C6.47917 2.09583 6.71667 2 7 2C7.28333 2 7.52083 2.09583 7.7125 2.2875C7.90417 2.47917 8 2.71667 8 3V4H16V3C16 2.71667 16.0958 2.47917 16.2875 2.2875C16.4792 2.09583 16.7167 2 17 2C17.2833 2 17.5208 2.09583 17.7125 2.2875C17.9042 2.47917 18 2.71667 18 3V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z"
      fill="#757575"
    />
  </svg>
);

const dayNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8];

type Props = {
  withDaySelect?: boolean;
  activeDayNumber: number;
  setDayNumber?: (dayNumber: number) => void;
};

export const Header = ({ withDaySelect = false, activeDayNumber, setDayNumber = () => {} }: Props) => {
  const [anchorEl, setAnchorEl] = useState<any>();

  const handleClick = (e: any) => {
    if (!withDaySelect) return;

    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.target);
    }
  };

  return (
    <>
      <div style={{ height: '60px' }} />
      <Wrapper>
        <Label>Map</Label>
        <Block onClick={handleClick} isActive={!!anchorEl}>
          <CalendarIcon />
          <Label>{getLabelFromDayNumber(activeDayNumber)}</Label>
        </Block>
        {withDaySelect && (
          <Popover
            onClose={() => setAnchorEl(null)}
            open={!!anchorEl}
            anchorEl={anchorEl}
            // onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <PopoverWrapper>
              <PopoverTitle>ITINERARY</PopoverTitle>
              <div />
              {dayNumbers.map(dayNumber => (
                <PopoverOption
                  onClick={() => {
                    setDayNumber(dayNumber);
                    setAnchorEl(null);
                  }}
                  isActive={dayNumber === activeDayNumber}
                >
                  {getLabelFromDayNumber(dayNumber)}
                </PopoverOption>
              ))}
            </PopoverWrapper>
          </Popover>
        )}
      </Wrapper>
    </>
  );
};
