import { useCallback, useEffect, useState } from 'react';
import getSocket from '../../services/socket';
import { useSelector } from 'react-redux';
import { SocketMessage } from '../../services/api/types';
import { WS_NOTIFICATION } from '../../constants/actionTypes';
import styled from 'styled-components';
import { AutoaxessLogo } from './AutoaxessLogo';
import { Icon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import WinSound from 'sound/win.mp3';
import ClockSound from 'sound/clock.mp3';
import OfferSound from 'sound/offer_that_comes.mp3';
import SuccessSound from 'sound/success.mp3';
import BidSound from 'sound/new_bid.mp3';
import WarningSound from 'sound/warning.mp3';
import { useSetFcmTokenMutation } from '../../services/api';

type Notification = {
  id: number;
  type: string;
  title: string;
  description: string;
  color: 'green' | 'red' | 'blue' | 'yellow';
};

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: fixed;
  z-index: 99;
  bottom: 40px;
  right: 60px;

  @media (max-width: 768px) {
    right: calc(50% - 190px);
  }
`;

const NotificationComponent = styled.div<{ color: string }>`
  display: flex;
  height: 130px;
  gap: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-left: 8px solid ${props => props.color};
  border-radius: 6px;
  position: relative;
  padding: 8px 16px 16px 8px;
  width: 380px;
  background: #fff;
`;

const NotificationContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotificationHeader = styled.div`
  font-size: 12px;
  color: #757575;
  font-weight: 700;
`;

const NotificationTitle = styled.div<{ color: string }>`
  margin-top: 6px;
  & > span {
    font-size: 14px;
    font-weight: 700;
    color: ${props => props.color};
  }

  & > span > span {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
`;

const CrossIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  color: #757575;
  cursor: pointer;
`;

const NotificationDescription = styled.div<{ linkColor: string }>`
  margin-top: 8px;
  font-size: 12px;

  & > span > span {
    text-decoration: underline;
    color: ${props => props.linkColor};
    cursor: pointer;
  }
`;

const LogoWrapper = styled.div`
  width: 32px;
  height: 32px;
`;

const colorMap = {
  green: '#479747',
  red: '#D50808',
  blue: '#3994DE',
  yellow: '#E47E00'
};

const soundMap: Record<string, any> = {
  auction_won: WinSound,
  buyers_private_offer_accepted: WinSound,
  '5_counter_min_left': ClockSound,
  car_sold: WinSound,
  private_offer_received: OfferSound,
  public_offer_received: OfferSound,
  seller_reserve_met: SuccessSound,
  admin_reserve_met: SuccessSound,
  admin_new_bid: BidSound,
  admin_car_sold: WinSound,
  seller_accepted_high_bid: WinSound,
  top_buyer_accepted_aa_offer: WinSound,
  seller_accepted_top_buyers_offer: WinSound,
  admin_awarded: WinSound,
  top_buyer_accepted_seller_offer: WinSound,
  aa_accepted_top_buyer_offer: WinSound,
  payment_pending: WarningSound,
  aa_confirmed_after_seller: WinSound,
  admin_buyer_accepted_public_counter: WinSound,
  admin_offer_received: OfferSound
};

// const firebaseConfig = {
//   apiKey: 'AIzaSyDF48R6ezD1K-bfs7EIefXPJPVzJegyHzs',
//   authDomain: 'speedy-toolbox-216207.firebaseapp.com',
//   projectId: 'speedy-toolbox-216207',
//   storageBucket: 'speedy-toolbox-216207.appspot.com',
//   messagingSenderId: '421193907971',
//   appId: '1:421193907971:web:bd68eefe53289ca4213336'
// };

export const NotificationListener = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const user = useSelector((state: any) => state.user?.user);

  const removeNotification = useCallback((id: number) => {
    setNotifications(prev => prev.filter(el => el.id !== id));
  }, []);

  const [setFcmToken] = useSetFcmTokenMutation();

  const history = useHistory();

  const navigationHandler: any = (e: any) => {
    e.preventDefault();
    const link = e.target?.getAttribute?.('data-link');
    if (link) {
      history.push(link);
    }
  };

  useEffect(() => {
    if (!user) return;
    const socket = getSocket();
    socket.emit('subscribe', `notifications:${user.id}`);
    socket.on('message', async (message: SocketMessage) => {
      const { type, payload } = message;
      console.log('notification message received ', type);
      if (type === WS_NOTIFICATION) {
        console.log(user, 'notifications sub');
        try {
          const sound = soundMap[payload.type];
          if (sound && user.audio_notifications_enabled !== false) {
            const audio = new Audio(sound);
            await audio.play();
          }
        } catch (e) {
          console.error(e);
        }

        if (payload.type === 'admin_new_bid') {
          return;
        }

        if (user.visual_notifications_enabled === false) {
          return;
        }

        setNotifications(prev => [...prev, payload]);

        setTimeout(() => {
          removeNotification(payload.id);
        }, 60 * 1000);
      }
    });

    return () => {
      socket.emit('unsubscribe', `notifications:${user.id}`);
      socket.off('message');
    };
  }, [history, removeNotification, user]);

  if (notifications.length === 0) {
    return null;
  }
  return (
    <NotificationWrapper>
      {notifications.map(notification => (
        <NotificationComponent key={notification.id} color={colorMap[notification.color]}>
          <CrossIconWrapper onClick={() => removeNotification(notification.id)}>
            <Icon>close</Icon>
          </CrossIconWrapper>
          <LogoWrapper>
            <AutoaxessLogo />
          </LogoWrapper>
          <NotificationContentWrapper>
            <NotificationHeader>AUTOAXESS</NotificationHeader>
            <NotificationTitle
              color={colorMap[notification.color]}
              dangerouslySetInnerHTML={{ __html: notification.title }}
            />
            <NotificationDescription
              linkColor={colorMap[notification.color]}
              onClick={navigationHandler}
              dangerouslySetInnerHTML={{ __html: notification.description }}
            />
          </NotificationContentWrapper>
        </NotificationComponent>
      ))}
    </NotificationWrapper>
  );
};
