import React, { useCallback, useEffect } from 'react';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

import Header from '../Header';
import Footer from '../Footer';
import Snack from '../Snack';
import styled from 'styled-components';

import './index.scss';
import { NotificationListener } from '../NotificationListener/NotificationListener';
import { setScrollPosition, useGetScrollPosition } from '../../services/scrollPosition/scrollPosititonSlice';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MobileNavigation } from 'components/mobile/Navigation';
import { useScreen } from 'usehooks-ts';
import { UserGeoProvider } from '../UserGeoProvider/UserGeoProvider';

class ExtendedMomentUtils extends MomentUtils {
  getWeekdays() {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  }
}

const LayoutWrapper = styled.div`
  display: ${props => (props.isFlexLayout ? 'flex' : 'block')};

  @media (max-width: 1280px) {
    display: block;
  }
`;

const Layout = props => {
  const { children } = props;

  const user = useSelector(state => state.user?.user);
  const location = useLocation();

  const isPageWithoutLayout =
    /^\/vehicles\/.*\/(autocheck-report|funding-notice)$/.test(location.pathname) ||
    location.pathname.includes('auction-summary-report/export');

  const isHomePage = location.pathname === '/';
  const isFlexLayout = location.pathname.includes('live-auction');

  const { width = 0 } = useScreen();

  const isMobile = width < 768;

  const showNavigation = isMobile && user && (user.role !== 'admin' || user.job_title === 'Lister');
  const isPageWithoutFooter = isMobile;
  const hideHeader = isMobile && !user?.id && location.pathname !== '/';

  const scrollPosition = useGetScrollPosition(location.pathname);

  const dispatch = useDispatch();

  const handleScroll = useCallback(() => {
    dispatch(setScrollPosition({ [location.pathname]: window.scrollY }));
  }, [dispatch, location.pathname]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [location.pathname]);

  const whiteMobileBackground =
    ['/login', '/register', '/forgot-password', '/success'].some(path => location.pathname.startsWith(path)) &&
    isMobile;

  if (isPageWithoutLayout) {
    return children;
  }

  return (
    <MuiPickersUtilsProvider utils={ExtendedMomentUtils}>
      <CssBaseline />
      <div
        className={`layout-holder ${
          isPageWithoutFooter && location.pathname !== '/live-auction' ? 'layout-holder-mobile' : ''
        } ${hideHeader ? 'no-header-layout' : ''} ${whiteMobileBackground ? 'white-background' : ''}`}
      >
        <NotificationListener />
        <UserGeoProvider />
        {!hideHeader && <Header {...props} />}
        <LayoutWrapper isFlexLayout={isFlexLayout} className="layout-body">
          {children}
        </LayoutWrapper>
        {!isPageWithoutFooter && <Footer noMargin={isHomePage} />}
        <Snack {...props} />
      </div>
      {showNavigation && <MobileNavigation />}
    </MuiPickersUtilsProvider>
  );
};

export default Layout;
