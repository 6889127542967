/* eslint-disable no-else-return */
import React, { useRef } from 'react';
import styled from 'styled-components';
import { numberFormatString } from '../../../../utils';
import { EasyPayStatus } from '../../../../components/EasyPay';
import { PAYMENT_METHODS, VEHICLE_STATUSES, BUYER_VEHICLE_STATUS } from '../../../../constants';
import Tooltip from '../../../../components/Tooltip';
import { useIsTextOverflown } from '../../../../hooks';
import { OfferAuctionStatus } from '../../../../types/vehicle';

const StyledStatus = styled.span`
  color: ${props => props.statusColor || '#000'};,
`;

const StyledDescription = styled.div`
  color: #757575;
`;

const AmountWrapper = styled.div`
  display: flex;
  gap: 2px;
`;

const StatusWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

const StatusName = styled.span`
  width: 60px;
  color: #757575;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BidStatus = ({ vehicle, currentUser, counterAmount, status, offerAuction }) => {
  const isAwarded = [
    BUYER_VEHICLE_STATUS.AWARDED,
    BUYER_VEHICLE_STATUS.AWARDED_NOT_CHARGED,
    BUYER_VEHICLE_STATUS.AWARDED_PENDING_CHARGE
  ].includes(status);
  const isUserAwarded = vehicle.buyer_id === currentUser.id;
  const easyPaid = vehicle.payment_method === PAYMENT_METHODS.EASY_PAY;

  const declinedTextRef = useRef(null);
  const declinedTextOverflown = useIsTextOverflown(declinedTextRef);

  if (isAwarded && isUserAwarded && easyPaid) {
    return <EasyPayStatus vehicle={vehicle} />;
  } else if (isAwarded && isUserAwarded && !easyPaid) {
    return (
      <StatusWrapper>
        <StyledStatus statusColor="#009F0F">Awarded</StyledStatus>
        <StyledDescription>{`(${numberFormatString(vehicle.vehicle_price || 0)})`}</StyledDescription>
      </StatusWrapper>
    );
  } else if (isAwarded && !isUserAwarded) {
    return (
      <StatusWrapper>
        <StyledStatus statusColor="#D50808">Declined - Awarded to</StyledStatus>
        <Tooltip
          isTextOverflown={declinedTextOverflown}
          disableFocusListener
          title={vehicle.awarded_user?.dealership_name}
          arrow
          light
        >
          <StatusName ref={declinedTextRef}>{vehicle.awarded_user?.dealership_name}</StatusName>
        </Tooltip>
      </StatusWrapper>
    );
  } else if (status === VEHICLE_STATUSES.PULLED) {
    return <StyledStatus statusColor="#E47E00">Pulled</StyledStatus>;
  } else if (status === 'declined') {
    return (
      <StatusWrapper>
        <StyledStatus statusColor="#D50808">Declined - Awarded to</StyledStatus>
        <Tooltip
          isTextOverflown={declinedTextOverflown}
          disableFocusListener
          title={vehicle.awarded_user?.dealership_name}
          arrow
          light
        >
          <StatusName ref={declinedTextRef}>{vehicle.awarded_user?.dealership_name}</StatusName>
        </Tooltip>
      </StatusWrapper>
    );
  } else if (status === 'high_bid') {
    return <StyledStatus statusColor="#009F0F">Active</StyledStatus>;
  } else if (status === 'out_bid') {
    return <StyledStatus statusColor="#009F0F">Active</StyledStatus>;
  } else if (status === 'undecided') {
    return <StyledStatus statusColor="#E47E00">Undecided</StyledStatus>;
  } else if (status === 'countered') {
    return (
      <AmountWrapper>
        <StyledStatus statusColor="#E47E00">Countered</StyledStatus>
        <StyledStatus>{numberFormatString(counterAmount ?? 0)}</StyledStatus>
      </AmountWrapper>
    );
  } else if (
    status === BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS ||
    status === BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER
  ) {
    const privateStage = offerAuction?.status === OfferAuctionStatus.PRIVATE_BIDS;
    const text = privateStage ? '' : 'Seller Accepting Offers'
    return <StyledStatus statusColor="#E47E00">{text}</StyledStatus>;
  } else if (status === BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT) {
    return (
      <AmountWrapper>
        <StyledStatus statusColor="#E47E00">Counter Sent to Seller</StyledStatus>
        <StyledStatus>{numberFormatString(counterAmount ?? 0)}</StyledStatus>
      </AmountWrapper>
    );
  }
  return <StyledStatus>None</StyledStatus>;
};

export default BidStatus;
