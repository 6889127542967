import { Vehicle } from '../../../../types/vehicle';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { ReservePriceMet, ReservePriceNotMet, ReservePriceNotSet } from '../../../../components/Icons';
import { useState } from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { ReservePriceHistory } from '../../../../components/ReservePrice/reservePriceHistory';

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  font-weight: 500;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
`;

type Props = {
  vehicle: Vehicle;
  classes: any;
};

const styles = () => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#616161',
    fontSize: 14,
    opacity: 1,
    width: 440,
    maxWidth: '100vw',
    marginTop: 6,
  },
  popper: {
    borderRadius: '4px',
    border: '1px solid #EEE',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#ffffff',
    opacity: 1,
    wordBreak: 'break-word',
    top: '20px !important'
  }
});

export const AdminReservePrice = withStyles(styles as any)(({ vehicle, classes }: Props) => {
  const [isHistoryOpened, setIsHistoryOpened] = useState(false);

  const openHistory = () => {
    if (!vehicle.reserve_price_history || vehicle.reserve_price_history.length === 0) return;
    setIsHistoryOpened(true);
  };

  const closeHistory = () => {
    setIsHistoryOpened(false);
  };

  const IndicatorIcon = (() => {
    if (!vehicle.reserve_price_set) {
      return ReservePriceNotSet;
    }

    if (vehicle.reserve_price_met) {
      return ReservePriceMet;
    }

    return ReservePriceNotMet;
  })();

  return (
    <Wrapper onMouseOver={openHistory} onMouseOut={closeHistory}>
      <Tooltip
        classes={{ tooltip: classes.tooltip, popper: classes.popper }}
        disableHoverListener={vehicle.reserve_price_history.length === 0}
        disableFocusListener
        disableTouchListener
        open={isHistoryOpened}
        title={
          <ReservePriceHistory vehicle={vehicle} />
        }
      >
        <ContentWrapper>
          <IndicatorIcon />
          {vehicle.reserve_price_set ? (
            <NumberFormat displayType="text" prefix="$" value={vehicle.reserve_price} thousandSeparator />
          ) : (
            '-'
          )}
        </ContentWrapper>
      </Tooltip>
    </Wrapper>
  );
});
