import { ClickAwayListener, Tooltip, withStyles } from '@material-ui/core';
import { UserHoverInfo } from './HoverInfo';

const styles = () => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#616161',
    fontSize: 14,
    opacity: 1,
    width: 400,
    maxWidth: '100vw',
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  popper: {
    backgroundColor: '#ffffff',
    opacity: 1,
    wordBreak: 'break-word',
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    top: '20px !important'
  }
});

export const HoverInfoTooltip = withStyles(styles as any)(
  ({ userId, children, classes, isTooltipOpen, closeTooltip, initialPage }: any) => {
    return (
      <Tooltip
        disableHoverListener
        disableFocusListener
        disableTouchListener
        open={isTooltipOpen}
        classes={{ tooltip: classes.tooltip, popper: classes.popper }}
        title={
          <ClickAwayListener onClickAway={closeTooltip}>
            <div onMouseLeave={closeTooltip}>
              <UserHoverInfo customerId={userId} initialPage={initialPage} />
            </div>
          </ClickAwayListener>
        }
      >
        {children}
      </Tooltip>
    );
  }
);
