import { useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';
import { useUpdateNumberOfVehiclesMutation } from '../../../services/api/list-vehicles';
import { useOptimisticVehicleNumberUpdate } from '../hooks/useOptimisticUpdates';

const Wrapper = styled.form`
  position: absolute;
  top: -8px;
  right: -8px;
  min-width: 20px;
  max-width: 120px;
  font-weight: 500;
  color: #000;
  border: 1px solid #757575;
  border-radius: 4px;
  padding: 2px 2px;
  height: 19px;
  background: #fff;
`;

const Content = styled.span``;

// const StyledInput = styled.input`
//   max-width: 100px;
//   font-size: 8px;
//   font-weight: 500;
//   line-height: 9.38px;
//   color: #000;
//   border: 1px solid #757575;
//   border-radius: 4px;
//   padding: 2px 2px;
//   background: #fff;
// `;

type Props = {
  id: number;
  closeForm: () => void;
  initialValue?: string;
};

export const EditNumberOfVehiclesForm = ({ id, closeForm, initialValue = '' }: Props) => {
  const ref = useRef<any>(null);
  const contentRef = useRef<any>(null);
  // const [value, setValue] = useState(initialValue);

  const optimisticVehicleNumberUpdate = useOptimisticVehicleNumberUpdate();
  const [updateFn] = useUpdateNumberOfVehiclesMutation();

  const handleSubmit = () => {
    const newValue = contentRef.current?.innerText?.trim();
    optimisticVehicleNumberUpdate(id, newValue);
    updateFn({ requestId: id, numberOfVehicles: newValue });
  };

  useOnClickOutside(ref, () => {
    handleSubmit();
    closeForm();
  });

  //   const handleChange = (e: any) => {
  //     setValue((e.target as any).value);
  //     console.log(e);
  //   };

  return (
    <Wrapper ref={ref}>
      <Content ref={contentRef} contentEditable>
        {initialValue}
      </Content>
    </Wrapper>
  );

  //   return (
  //     <Form
  //       onSubmit={(values: any) => {
  //         console.log(values);
  //       }}
  //     >
  //       {props => (
  //         <Wrapper ref={ref} onSubmit={props.handleSubmit}>
  //           <Field name="number_of_vehicles">{({ input }) => <StyledInput {...input} />}</Field>
  //         </Wrapper>
  //       )}
  //     </Form>
  //   );
};
