import {
  OutbidLabel,
  TopBidderLabel,
  TopBidderLabelMobile,
  OutbidLabelMobile,
  TopOfferLabel,
  OutbidOfferLabel
} from '../../../../../img/bid-statuses';
import { useSelector } from 'react-redux';
import { useScreen } from 'usehooks-ts';
import { MOBILE_BREAKPOINT } from '../../../../../constants';
import { getHighestBid } from '../../../../../utils/vehicle';
import { useBidsOfferAuction } from '../hooks';
import { OfferAuctionStatus } from '../../../../../types/vehicle';

type IconResolver = Record<string, () => JSX.Element>;

const IconMap: Record<'left' | 'right', IconResolver> = {
  right: {
    high_bid: TopBidderLabelMobile,
    out_bid: OutbidLabelMobile,
    high_offer: TopOfferLabel,
    outbid_offer: OutbidOfferLabel
  },
  left: { high_bid: TopBidderLabel, out_bid: OutbidLabel, high_offer: TopOfferLabel, outbid_offer: OutbidOfferLabel }
};

type TableIconProps = {
  id: number;
  item: any;
  labelType?: 'left' | 'right';
};

export const BidInfoLabel = ({ item: currentVehicle, labelType }: TableIconProps) => {
  const user = useSelector((state: any) => state.user?.user);
  const screen = useScreen();

  const offerAuction = useBidsOfferAuction(currentVehicle.id);
  const highestBid = getHighestBid(currentVehicle);
  const topBidder = highestBid.user_id === user?.id;
  const status = (() => {
    if (offerAuction?.status === OfferAuctionStatus.PUBLIC_OFFERS) {
      const isTopOffer = offerAuction?.offerProxyBid?.user_id === user?.id;
      return isTopOffer ? 'high_offer' : 'outbid_offer';
    }

    return topBidder ? 'high_bid' : 'out_bid';
  })()

  const resolvedLabelType = (() => {
    if (labelType) return labelType;
    return screen && screen.width <= MOBILE_BREAKPOINT ? 'right' : 'left';
  })();

  const LabelComponent = IconMap[resolvedLabelType][status];
  if (!LabelComponent) return null;

  return <LabelComponent />;
};
